.container {
	scroll-behavior: smooth;
	margin: 0 auto 65px auto;
	max-width: 400px;
	padding: 10px 20px;
}

.logo {
	display: block;
	width: 80px;
	margin: 0 auto;
	margin-bottom: 40px;
}

.title {
	font-family: 'Recoleta';
	font-size: 36px;
	color: #83b200;
}

.mainList {
	padding-left: 20px;
}

.mainListItem {
	color: #707070;
	font-weight: 400;
	margin-bottom: 10px;
}

.mainListItem a {
	text-decoration: none;
	color: #56b6ea;
	line-height: 1.4;
	white-space: pre-wrap;
}

.content ul.subList {
	list-style-type: disc;
	padding: revert;
	line-height: initial;
}

.content li.subListItem {
	color: #707070;
	font-weight: 400;
	margin-bottom: 10px;
}

.content li.subListItem a {
	text-decoration: none;
	color: #56b6ea;
}

.content h2 {
	font-size: 24px;
	font-weight: 600;
	margin: 40px 0 10px 0;
}

.content h3 {
	font-size: 18px;
	line-height: 1.4;
	font-weight: 600;
}

.content p {
	margin: 0;
	line-height: 1.5;
	margin-bottom: 8px;
	white-space: pre-wrap;
}

.content a {
	color: #56b6ea;
	text-decoration: none;
}

.content ol,
.content ul {
	margin: 0;
	padding: 0 20px;
	line-height: 1.5;
	margin-bottom: 8px;
	white-space: normal;
}

.content ol li,
.content ul li {
	margin-bottom: 8px;
}

.subtitle {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 10px;
}

.content .subtitle h2 {
	margin: 0;
}

.subtitle div {
	cursor: pointer;
}

@media (min-width: 440px) {
	.menu {
		margin-bottom: 0;
	}

	.container {
		max-width: 535px;
		margin: 0 0 0 480px;
	}

	.logo {
		display: none;
	}
}
