.dialog {
	background-color: #f8f7f5 !important;
	border-radius: 20px !important;
	box-sizing: border-box;
}

.dialogFullScreen {
	border-radius: 0 !important;
}

.closeButton {
	z-index: 100;
	position: absolute !important;
	top: 16px;
	right: 16px;
}

.dialogContent {
	padding: 16px;
	overflow: auto;
}
