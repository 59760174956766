.menuContainer {
	padding: 15px 30px;
	border-top: 1px solid #e3e3e3;

	&.hideDesktop {
		padding: 0;
		border-top: none;
	}
}

.menuItem {
	text-decoration: none;
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 15px 0;
}

.menuItem svg {
	width: 25px;
}

.menuItem:not(:first-child) {
	border-top: 1px solid #dbd9d9;
}

a.menuItem,
a:visited.menuItem {
	color: #707070;
	font-size: 22px;
	font-weight: 400;
}

@media (min-width: 440px) {
	.menuContainer.hideDesktop {
		display: none;
	}
}
