.bottomContainer {
	text-align: center;
	margin-top: auto;
	padding-top: 16px;
	display: flex;
	justify-content: space-between;
}

.abortButton {
	color: #707070 !important;
	text-transform: initial !important;
}

.stepper {
	background-color: #83b200 !important;
	border-radius: 8px;
	margin-top: auto;
	margin-bottom: -16px;

	& > button {
		color: rgba(0, 0, 0, 0.87);
	}
}

.stepperDot {
	background-color: #2d2147 !important;
}

.logo {
	max-width: 120px;
	display: block;
}

.nextButton {
	font-weight: 600 !important;
	font-family: 'Recoleta' !important;
	font-size: 14px !important;
	text-transform: none !important;

	&:not(:disabled) {
		color: #eee;
		background-color: #83b200;
	}
}

.stepContainer {
	font-family: 'DM Sans';
	font-size: 12px;
	display: flex;
	flex-direction: column;
}

.pretestContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 290px;
	margin: 0 auto;
	line-height: 1.5em;
}
