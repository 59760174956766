.container {
	position: relative;
	box-sizing: border-box;
}

.container > input,
.container > fieldset,
.container > select {
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	border-radius: 3px;
	padding: 22px 15px 5px 15px;
}

.container > select {
	/* Override platform default styles and add a custom arrow */
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	border: none;
	font-size: 16px;
	padding-left: 14px;
	padding-right: 30px;
	cursor: pointer;
	white-space: nowrap;
	text-overflow: ellipsis;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2383b200'%3e%3cpath d='M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 8px top 22px;
	background-size: 16px;
}

.label {
	position: absolute;
	top: 5px;
	left: 15px;
	font-size: 10px;
	color: #aab5b8;
	font-weight: 600;
	/* Make sure label is always on top, for example with MUI components */
	z-index: 2;
}
