.container {
	margin: 0 auto 65px auto;
	padding: 10px 20px;
	max-width: 500px;
}

.logo {
	display: block;
	width: 80px;
	margin: 0 auto;
	margin-bottom: 40px;
}

.title {
	font-family: 'Recoleta';
	font-size: 36px;
	color: #83b200;
	margin-bottom: 30px;
}

.challengeList {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.challenge {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 20px;
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	background-color: white;
}

/* Clear Link styling */
a.challenge,
a:visited.challenge {
	color: unset;
	text-decoration: none;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.challengeTitle {
	font-size: 20px;
	font-weight: 600;
	margin: 0;
}

.date {
	font-size: 14px;
	color: #666;
}

.description {
	font-size: 14px;
	margin: 0;
}

@media (min-width: 440px) {
	.container {
		margin: 0;
		padding-top: 140px;
	}

	.logo {
		display: none;
	}
}
