.container {
	margin: 0 auto 65px auto;
	padding: 10px 0;
	max-width: 500px;
}

.logo {
	display: block;
	width: 80px;
	margin: 0 auto;
	margin-bottom: 40px;
}

.cardContainer {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.cardContainer .accordion {
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	background-color: white;
}

.cardContainer .accordionSummary {
	padding: 0 20px;
}

.cardContainer .accordionDetails {
	padding: 8px 20px 20px 20px;
}

.checklistTitle {
	font-weight: 600;
}

.checklistContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.checklistItem {
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
}

.checklistSubtitle {
	font-weight: 600;
}

.checklistSubtitle svg {
	visibility: hidden;
}

.card {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 20px;
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	background-color: white;
}

.card hr {
	width: 100%;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.challengeTitle {
	font-size: 20px;
	font-weight: 600;
	margin: 0;
}

.dateContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.date {
	font-size: 14px;
	color: #666;
}

.daysLeft {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;
	font-size: 14px;
	font-weight: bold;
}

.daysLeft svg {
	color: #ffaa00;
}

.description {
	font-size: 14px;
	margin: 0;
}

.leaderboardTable td {
	white-space: nowrap;
	padding: 6px 0;
}

td.rankingCell {
	font-weight: bold;
	font-size: 24px;
}

td.userCell {
	width: 100%;
	max-width: 0;
	padding: 6px 16px;
}

.userContainer {
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
}

.avatar {
	flex-shrink: 0;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	align-content: center;
	text-align: center;
}

.avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.avatar svg {
	width: 50%;
	height: 50%;
}

.username {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

td.scoreCell {
	font-weight: bold;
	text-align: right;
}

@media (min-width: 441px) {
	.container {
		margin: 0;
		padding: 140px 20px 10px 20px;
	}

	.logo {
		display: none;
	}
}
