.container {
	margin: 0 auto 65px auto;
	padding: 10px 20px;
}

.logo {
	display: block;
	width: 80px;
	margin: 0 auto;
	margin-bottom: 40px;
}

.title {
	font-family: 'Recoleta';
	font-size: 36px;
	color: #83b200;
	margin-bottom: 30px;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.inputContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.input {
	width: 100%;
	box-sizing: border-box;
}

.avatarContainer {
	display: flex;
	gap: 16px;
	flex-direction: column;
	align-items: center;
}

.avatar {
	flex-shrink: 0;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: #757a97;
	overflow: hidden;
	align-content: center;
	text-align: center;
}

.avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.avatar svg {
	width: 50%;
	height: 50%;
}

.avatarInput {
	display: none;
}

.avatarButtons {
	display: flex;
	gap: 16px;
}

@media (min-width: 440px) {
	.container {
		margin: 0;
		padding-top: 140px;
	}

	.logo {
		display: none;
	}

	.form {
		display: inline-flex;
		display: inline flex;
	}

	.inputContainer {
		display: inline-flex;
		display: inline flex;
	}
}
