@font-face {
	font-family: 'DM Sans';
	src:
		url('./fonts/DMSans-Regular.ttf') format('woff'),
		url('./fonts/DMSans-MediumItalic.ttf') format('woff'),
		url('./fonts/DMSans-Bold.ttf') format('woff'),
		url('./fonts/DMSans-BoldItalic.ttf') format('woff'),
		url('./fonts/DMSans-Italic.ttf') format('woff'),
		url('./fonts/DMSans-Medium.ttf') format('woff');
}

@font-face {
	font-family: 'Recoleta';
	src:
		url('./fonts/088d274f-4e52-420a-9344-ef1fc51706a0.woff2') format('woff2'),
		url('./fonts/f4613aaa-0b7b-4577-8100-7a2637ca1c5b.woff') format('woff');
}

body {
	background-color: #f8f7f5;
	color: #2d2047;
}

.App {
	text-align: left;
	background-color: #f8f7f5;
}

.App a,
a:visited {
	text-decoration: none;
	color: #84b200;
}

.classificationComponent {
	height: 30px;
	user-select: none;
}

.classificationComponent input {
	width: 280px;
	height: 18px;
	appearance: none;
	position: absolute;
	z-index: 2;
	top: -10px;
	left: 0;
	overflow: hidden;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	user-select: none;
	outline: none;
}

.classificationComponent input:focus {
	outline: none !important;
	user-select: none !important;
}

.classificationComponent input[type='range']::-moz-focus-outer {
	border: 0;
	user-select: none;
	outline: none;
}

.classificationComponent input[type='range']::-moz-range-track {
	background-color: transparent;
	user-select: none;
}

.classificationComponent input[type='range']::-moz-range-thumb {
	width: 10px;
	-webkit-appearance: none;
	height: 10px;
	border-radius: 50%;
	cursor: pointer;
	background: #96a2a6;
	border: 1px solid #fff;
	user-select: none;
	outline: none;
}

.classificationComponent input[type='range']::-webkit-slider-thumb {
	width: 10px;
	-webkit-appearance: none;
	height: 10px;
	border-radius: 50%;
	cursor: pointer;
	background: #96a2a6;
	border: 1px solid #fff;
	user-select: none;
	outline: none;
}

.classificationComponent input[type='range'].bad::-webkit-slider-thumb {
	background-color: #ec6d6e;
	width: 18px;
	height: 18px;
	top: -10px;
}
.classificationComponent input[type='range'].needsWork::-webkit-slider-thumb {
	background-color: #f3b442;
	width: 18px;
	height: 18px;
	top: -10px;
}

.classificationComponent input[type='range'].good::-webkit-slider-thumb {
	background-color: #96b63c;
	width: 18px;
	height: 18px;
	top: -10px;
}

.classificationComponent input[type='range'].great::-webkit-slider-thumb {
	width: 18px;
	height: 18px;
	background-color: #59864e;
	top: -10px;
}

.classificationComponent input[type='range'].bad::-moz-range-thumb {
	background-color: #ec6d6e;
	width: 18px;
	height: 18px;
	top: -10px;
}
.classificationComponent input[type='range'].needsWork::-moz-range-thumb {
	background-color: #f3b442;
	width: 18px;
	height: 18px;
	top: -10px;
}

.classificationComponent input[type='range'].good::-moz-range-thumb {
	background-color: #96b63c;
	width: 18px;
	height: 18px;
	top: -10px;
}

.classificationComponent input[type='range'].great::-moz-range-thumb {
	width: 18px;
	height: 18px;
	background-color: #59864e;
	top: -10px;
}

.classificationComponent .title {
	margin-bottom: 12px;
	font-weight: 600;
	margin-left: 4px;
	font-family: 'DM Sans';
	color: #707070;
}

.classificationBarWrapper {
	display: flex;
}

.classificationBarWrapper div {
	margin-right: 12px;
}

.classificationBarWrapper div:first-child {
	margin-left: 4px;
}

.classificationBarWrapper div:last-child {
	margin-right: 0;
}

.classificationBar {
	display: block;
	width: 60px;
	height: 2px;
	border-radius: 8px;
}

.classificationBar.active {
	position: relative;
	top: -1px;
	height: 4px;
}

.classificationBar.bad {
	background-color: #ec6d6e;
}

.classificationBar.needsWork {
	background-color: #f3b442;
}

.classificationBar.good {
	background-color: #96b63c;
}

.classificationBar.great {
	background-color: #59864e;
}

.mm-popup {
	position: fixed;
	top: 48px;
	left: calc(50% - 120px);
	width: 240px;
	height: 115px;
	background-color: #fff;
	z-index: 1001;
	border-radius: 6px;
	box-shadow: 0 1px 6px 2px #e0e0e0;
	transition: visibility 50ms cubic-bezier(0, 1.07, 0.16, 1.03) 0ms;
}

.mm-popup .title {
	font-size: 10px;
	font-family: 'DM Sans';
	text-transform: uppercase;
	text-align: center;
	margin-top: 4px;
	color: #c6c6c6;
	font-weight: 600;
}

.mm-popup .classTag {
	display: flex;
	color: #747474;
	font-size: 14px;
	text-align: center;
	align-items: center;
	margin: 8px auto;
	font-weight: 600;
	width: 120px;
	font-family: 'DM Sans';
}

.mm-popup .classText {
	font-size: 12px;
	color: #747474;
	font-family: 'DM Sans';
	text-align: center;
	margin: 0 12px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group {
	background-color: transparent !important;
	box-shadow: none !important;
}

.mapboxgl-ctrl-group button {
	border-radius: 50% !important;
	background-color: #fff !important;
	width: 52px !important;
	height: 52px !important;
	box-shadow: rgb(224 224 224) 0px 1px 6px 2px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHZlcnNpb249IjEuMSI+CjxnIGlkPSJzdXJmYWNlMSI+CjxwYXRoIHN0eWxlPSIgc3Ryb2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoMCUsMCUsMCUpO2ZpbGwtb3BhY2l0eTowLjU0MTE3NjsiIGQ9Ik0gMjggNCBMIDQgMTQuMDM5MDYyIEwgNCAxNS4zNDc2NTYgTCAxMy4xMjEwOTQgMTguODc4OTA2IEwgMTYuNjQwNjI1IDI4IEwgMTcuOTQ1MzEyIDI4IFogTSAyOCA0ICIvPgo8L2c+Cjwvc3ZnPgo=') !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate-active .mapboxgl-ctrl-icon {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyIDMyIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiPgoJPHRpdGxlPmltYWdlICgxKS1zdmc8L3RpdGxlPgoJPHN0eWxlPgoJCS5zMCB7IGZpbGw6IG5vbmUgfSAKCQkuczEgeyBmaWxsOiAjNWZhYmUzIH0gCgk8L3N0eWxlPgoJPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGFzcz0iczAiIGQ9Im0zMiAwdjMyaC0zMnYtMzJ6Ii8+Cgk8cGF0aCBjbGFzcz0iczEiIGQ9Im0yOCA0bC0yNCAxMHYxLjNsOS4xIDMuNiAzLjUgOS4xaDEuM3oiLz4KPC9zdmc+') !important;
}

.mapboxgl-ctrl-attrib a,
.mapboxgl-ctrl-attrib a:visited {
	color: unset;
}

.journey-tracker-popup-info {
	font-family: 'DM Sans';
	font-size: 14px;
	color: #747474;
	font-weight: 600;
	text-align: center;
}

.routeListItem {
	background-color: #fff;
	padding: 4px 8px;
	font-size: 14px;
	color: #404040;
	cursor: pointer;
	border-radius: 8px;
	position: relative;
}
.routeListItem:hover {
	background-color: #f4f4f4;
}

.routeListItemActive {
	background-color: #5fabe3;
	color: #fff;
}
.routeListItemNotFound {
	background-color: #fff;
	padding: 4px 8px;
	font-size: 14px;
	color: #404040;
	border-radius: 8px;
}
