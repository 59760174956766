.wrapper {
	z-index: 100;
	position: fixed;
	top: 16px;
	left: 16px;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 6px;
}

@media (max-width: 440px) {
	.wrapper {
		display: none;
	}
}

.container {
	padding: 8px 30px;
	height: 64px;
	box-sizing: border-box;
	display: flex;
}

.menu {
	display: flex;
	align-items: stretch;
	text-align: center;
	gap: 24px;
	margin-left: 24px;
	padding: 4px 0;
}

.logo {
	width: 50px;
	padding-right: 24px;
	border-right: 1px solid #e3e3e3;
}

.label {
	font-size: 10px;
}

.topLink {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 14px;
	line-height: 43px;
	text-decoration: none;
	color: #ff565d !important;
	padding-right: 26px;
	border-right: 1px solid #e3e3e3;
}

.votingActions {
	border-top: 1px solid #e3e3e3;
	padding: 24px 20px;
}

.segment {
	color: #5fabe3;
	font-weight: 600;
	font-size: 16px;
}

.votingText {
	max-width: 300px;
	font-size: 22px;
	color: #2b2245;
	line-height: 33px;
	margin: 20px 0 30px 0;
}

.votes {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	& > * {
		cursor: pointer;
	}
}

.voteSubmitting {
	min-height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.description {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 4px;
	font-weight: 600;
	user-select: none;
}

.descriptionBad {
	color: #ff565d;
}

.descriptionGood {
	color: #1c8131;
}

button.menuLink {
	border: none;
	margin: 0;
	padding: 0;
	background: transparent;
}

.menuLink {
	width: 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	color: #707070;
	cursor: pointer;

	& svg path {
		fill: #707070;
	}

	&:hover,
	&.active {
		color: #5fabe3;

		& svg path {
			fill: #5fabe3;
		}
	}

	&:disabled {
		cursor: not-allowed;
		color: #a3a3a3;

		& svg path {
			fill: #a3a3a3;
		}
	}
}

.profileMenu {
	margin-top: 20px;
	border-radius: 20px !important;
	box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 6px !important;
}

.logout {
	color: #c54348 !important;
}
