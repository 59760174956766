.button {
	outline: none;
	text-decoration: none;
	border: none;
	cursor: pointer;
	padding: 8px 0;
	border-radius: 10px;
	font-size: 16px;
	display: flex;
	align-items: center;
	width: max-content;
	background-color: transparent;
	width: fit-content;
}

.disabled,
.button:disabled {
	cursor: not-allowed;
}

.icon {
	padding-top: 6px !important;
	padding-bottom: 6px !important;
}

.fit {
	width: 100%;
	display: flex;
	justify-content: center;
}

.apple {
	padding: 8px 16px;
	background-color: #83b200;
	border: 1px solid #83b200;
	color: #fff;

	&:disabled {
		background-color: #cecbc8;
		border-color: #cecbc8;
	}
}

.cherry {
	padding: 8px 16px;
	background-color: transparent;
	border: 1px solid #ff565d;
	border-radius: 9px;
	color: #c54348;

	&:disabled {
		border-color: #cecbc8;
		color: #707070;
	}
}

.silver {
	padding: 8px 16px;
	background-color: transparent;
	border: 1px solid #e7e7e7;
	border-radius: 9px;
	color: #707070;
}

.buttonContent {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0;
	transition: gap 0.2s ease-out;

	&.isLoading {
		gap: 16px;

		.progressContainer {
			width: 16px;
			height: 16px;
		}
	}
}

.progressContainer {
	display: flex;
	margin: 0 auto;
	width: 0;
	height: 0;
	transition:
		width 0.2s ease-out,
		height 0.2s ease-out;
}

.progress {
	color: rgba(0, 0, 0, 0.26) !important;
	width: 16px !important;
	height: 16px !important;
}
