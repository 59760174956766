.menu {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 4;
	background-color: #fff;
	width: 100%;
	border-top: 1px solid #efefef;
	border-bottom: 1px solid #efefef;
	padding: 8px 0;
}

.iconWrapper {
	display: flex;
	text-align: center;
	font-size: 12px;
	font-family: 'DM Sans';
	color: #797979;
	font-weight: 300;
	align-items: stretch;
	justify-content: space-evenly;
}

button.menuLink {
	border: none;
	margin: 0;
	padding: 0;
	background: transparent;
}

.menuLink {
	width: 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	color: #707070;
	cursor: pointer;

	& svg path {
		fill: #707070;
	}

	&:hover,
	&.active {
		color: #5fabe3;

		& svg path {
			fill: #5fabe3;
		}
	}

	&:disabled {
		cursor: not-allowed;
		color: #a3a3a3;

		& svg path {
			fill: #a3a3a3;
		}
	}
}

.globalVotingMap {
	max-width: 350px;
}

.paragraph {
	padding: 0 20px;
	text-align: center;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.4;
	margin-top: 0;
	margin-bottom: 20px;
	user-select: none;
}

.votes {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;

	& > * {
		cursor: pointer;
	}
}

.voteSubmitting {
	min-height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.description {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 4px;
	font-weight: 600;
	padding: 0 20px;
	user-select: none;
}

.descriptionBad {
	color: #ff565d;
}

.descriptionGood {
	color: #1c8131;
}

.profileMenu {
	margin-top: -20px;
	border-radius: 20px !important;
	box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 6px !important;
}

.logout {
	color: #c54348 !important;
}

.drawer {
	position: fixed;
	bottom: 60px;
	left: 0;
	width: 100%;
	z-index: 3;
	height: 18px;
	background-color: #fff;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	box-shadow: 0 1px 6px 2px #bbbbbb;
	transition: height 50ms cubic-bezier(0, 1.07, 0.16, 1.03) 0ms;

	h3 {
		font-size: 16;
		font-family: 'DM Sans';
		font-weight: 600;
		color: #8f8f8f;
	}

	p {
		font-size: 14;
		font-family: 'DM Sans';
		font-weight: 300;
		color: #989898;
	}
}

@media (min-width: 441px) {
	.menu {
		display: none;
	}

	.drawer {
		display: none;
	}
}
